<template>
    <div class="container-lesson lesson lesson-related" id="card_size" ref="card_size">
        <h5 class="title">{{ $t("lesson.related") }}</h5>
        <carousel
            :items="5"
            :stagePadding="100"
            :margin="28"
            :navText="navMenu"
            :responsive="slideResponsive"
            :dots="false"
            :slideBy="4"
            :rewind="false"
            v-if="items.length > 0"
        >
            <template>
                <router-link
                    :to="{ name: 'lesson.detail', params: { lessonId: item.id } }"
                    class="no-decoration"
                    v-for="(item, key) in items"
                    :key="key"
                >
                    <div class="cimg">
                        <img
                            :src="item.thumbnail"
                            :alt="item.thumbnail"
                            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                            :title="item.title"
                        />
                    </div>
                    <div class="description text-center">
                        <h5 class="custom-text-overflow mt-2">{{ item.title }}</h5>
                        <div class="introduction custom-text-overflow">
                            {{ item.teacher_name }}
                        </div>
                        <div class="price text-left">
                            {{ formatCurrency(item.currency_code, item.price) }} {{ $t("lesson.included_tax") }}
                        </div>
                        <div class="reviewed-star rate text-left">
                            <span class="star">
                                <star-rating
                                    :rating="Number(item.rated)"
                                    :star-size="18"
                                    :show-rating="false"
                                    :read-only="true"
                                />
                            </span>
                            <span>
                                {{ item.rated }}
                                {{ item.reviewed ? `(${item.reviewed})` : `` }}
                            </span>
                        </div>
                    </div>
                </router-link>

            </template>

        </carousel>
    </div>
</template>
<script>
import carousel from "vue-owl-carousel";

export default {
    name: "RelatedLesson",
    components: {
        carousel,
    },
    props: {
        items: [],
    },
    data() {
        return {
            navMenu: [
                '<span class="icon">&#8592;</span>',
                '<span class="icon">&#8594;</span>',
            ],
            slideResponsive: {
                0: {
                    items: 1,
                    stagePadding: 20,
                    margin: 10,
                },
                768: {
                    items: 3,
                    stagePadding: 40,
                    margin: 20,
                },
                1024: {
                    items: 4,
                    stagePadding: 45,
                    margin: 25,
                },
                1200: {
                    items: 5,
                    stagePadding: 50,
                    margin: 28,
                },
            }
        };
    },
};
</script>
<style scoped>

</style>