<template>
    <div class="review">
        <h1 class="title">{{ $t("lesson.detail.review") }}</h1>
        <div class="line-top"></div>
        <div v-if="comment.data.length > 0">
            <div v-for="(data, key) in comment.data" :key="key">
                <div class="d-flex justify-content-between">
                    <div>
                        <div class="reviewed-star d-flex">
                            <star-rating :rating="Number(data.rate)" :star-size="18" :show-rating="false"
                                :read-only="true" />
                            <small class="rate">{{ _totalRate(data.rate) }}</small>
                        </div>
                    </div>
                    <small>{{ formatDateInstance(data.created_at) }}</small>
                </div>
                <div class="descr">
                    <p>{{ data.content }}</p>
                    <router-link v-if="data.member.role === 'teacher'" :to="'/teacher/' + data.member.id + '/profile'">
                        <small><img class="img-profile" :src="data.member.profile_image" />{{ data.member.name }} </small>
                    </router-link>
                    <router-link v-else :to="'/student/' + data.member.id + '/profile'">
                        <small><img class="img-profile" :src="data.member.profile_image" />{{ data.member.name }} </small>
                    </router-link>
                </div>
            </div>
            <div class="text-center">
                <button class="btn" @click="handleLoadMore" :disabled="_noMoreComment">
                    {{ _loadMore }}
                </button>
                <br />
            </div>
        </div>
        <div class="descr" v-else>
            <p>{{ $t("lesson.detail.no_comment_yet") }}</p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        lessonId: Number,
    },
    data() {
        return {
            comment: {
                data: [],
                next_page_url: null
            },
            filter: {
                page: 1,
            },
        };
    },
    async created() {
        await this.actionFetchComments({
            lessonId: this.lessonId,
            params: this.filter,
        });

        this.comment = this.getComments;
    },
    computed: {
        ...mapGetters({ getComments: "lessonDetail/getLessonComments" }),

        _loadMore() {
            if (!this.comment.meta) {
                return this.$t("lesson.detail.no_more_comment");
            }
            return this.comment.meta.pagination.current < this.comment.meta.pagination.last
                ? this.$t("lesson.detail.loadmore")
                : this.$t("lesson.detail.no_more_comment");
        },
        _noMoreComment() {
            if (!this.comment.meta) {
                return true;
            }
            return this.comment.meta.pagination.current == this.comment.meta.pagination.last;
        },
    },
    methods: {
        ...mapActions({ actionFetchComments: "lessonDetail/fetchComments" }),
        async handleLoadMore() {
            let checkMoreData = this._noMoreComment;
            if (!checkMoreData) {
                this.filter.page = this.filter.page + 1;
                await this.actionFetchComments({
                    lessonId: this.lessonId,
                    params: this.filter,
                });
                let items = this.comment.data;
                this.comment = {
                    meta: this.getComments.meta,
                    data: items.concat(this.getComments.data)
                };
            }
        },
        _totalRate(rate) {
            rate = rate.toFixed(1);
            return rate;
        },
    },
    watch: {
        async lessonId(id) {
            await this.actionFetchComments({
                lessonId: id
            });
            this.comment = this.getComments;
        }
    }
};
</script>

<style></style>